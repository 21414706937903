import React from "react";
// import ProductPage from "../ProductsPage/ProductPage.jsx";
import ProductPage from "../../components/Products/ProductPage";

import brands from "../../assets/img/products/instrumentation/brands.png"

import p1 from "assets/img/products/instrumentation/1.jpg";
import p2 from "assets/img/products/instrumentation/2.jpg";
import p3 from "assets/img/products/instrumentation/3.jpg";
import p4 from "assets/img/products/instrumentation/4.jpg";
import p5 from "assets/img/products/instrumentation/5.jpg";
import p6 from "assets/img/products/instrumentation/6.jpg";
import p7 from "assets/img/products/instrumentation/7.jpg";
import p8 from "assets/img/products/instrumentation/8.jpg";
import p9 from "assets/img/products/instrumentation/9.jpg";
import p10 from "assets/img/products/instrumentation/10.jpg";
import p11 from "assets/img/products/instrumentation/11.jpg";
import p12 from "assets/img/products/instrumentation/12.jpg";
import p13 from "assets/img/products/instrumentation/13.jpg";
import p14 from "assets/img/products/instrumentation/14.jpg";
import p15 from "assets/img/products/instrumentation/15.jpg";
import p16 from "assets/img/products/instrumentation/16.jpg";

import s1 from "assets/img/products/instrumentation/slider/1.jpg";
import s2 from "assets/img/products/instrumentation/slider/2.jpg";

export default () => (
  <ProductPage prod="Instrumentation" url="instrumentation" brandsImg={brands} products={products} sliders={sliders} />
);

const sliders = [
    { img: s1},
    { img: s2}
]

const products = [
  { name: 'Power Logger',      img: p1 },
  { name: 'Anemometers',          img: p2 },
  { name: 'Contact Thermometers',            img: p3 },
  { name: 'Phase Testers',           img: p4 },
  { name: 'Basic Multimeters',           img: p5 },
  { name: 'Laser Levels ',             img: p6 },
  { name: 'Pressure Calibrator',img: p7 },
  { name: 'Scanners & Testers',   img: p8 },
  { name: 'Sound Level Meters',           img: p9 },
  { name: 'Temperature And Humidity ',        img: p10 },
  { name: 'Voltage Detectors',            img: p11 },
  { name: 'Tachometers',           img: p12 },
  { name: 'Infrared Thermometers',       img: p13 },
  { name: 'Basic Clamp Meters',             img: p14 },
  { name: 'Levels',         img: p15 },
  { name: 'Airflow Meter',     img: p16 }
]